import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { Layout, PageEnum } from "../components/layout"
import AboutSection from "../components/about-section"
import SkillsSection from "../components/skills-section"
import AwardsSection from "../components/awards-section"
import ContactSection from "../components/contact-section"
import DownloadCvButton from "../components/download-cv-button"

const AboutSectionStyled = styled(AboutSection)`
  margin-top: 20px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 100px;
  }
`

const SkillsSectionStyled = styled(SkillsSection)`
  margin-top: 60px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 390px;
  }
`

const DownloadCvButtonStyeled = styled(DownloadCvButton)`
  align-self: center;
  margin-top: 48px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 90px;
  }
`

const AwardsSectionStyled = styled(AwardsSection)`
  margin-top: 164px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 200px;
  }
`

const ContactSectionStyled = styled(ContactSection)`
  margin-top: 180px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 460px;
  }
`

const AboutPage = () => (
  <Layout page={PageEnum.ABOUT}>
    <SEO pageTitle="About" />
    <AboutSectionStyled />
    <SkillsSectionStyled />
    <DownloadCvButtonStyeled />
    <AwardsSectionStyled />
    <ContactSectionStyled />
  </Layout>
)

export default AboutPage
