import React from "react"
import styled from "styled-components"
import {
  paddingMobileMixin,
  paddingTabletMixin,
  paddingDesktopMixin,
} from "../assets/styles/paddingStyle"
import { h3Mixin, body1FontMixin } from "../assets/styles/fontStyle"

const Container = styled.section`
  ${paddingMobileMixin}
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    ${paddingTabletMixin}
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    ${paddingDesktopMixin}
    position: relative;
  }
`

const Title = styled.h3`
  ${h3Mixin}
  text-transform: uppercase;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    max-width: 30rem;
  }
`

const AwardsWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: auto;
  gap: 24px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 84px;
    grid-template-columns: max-content max-content;
    grid-template-rows: auto auto;
    gap: 40px;
  }
`

const AwardText = styled.div`
  ${body1FontMixin}
`

const AwardTitle = styled(AwardText)`
  font-weight: 600;
`

type Props = {}
const AwardsSection = (props: Props) => {
  return (
    <Container {...props}>
      <Title>AWARDS &amp; RECOGNITIONS</Title>
      <AwardsWrapper>
        <div
          data-sal="slide-left"
          data-sal-delay="300"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          <AwardTitle>Social Innovation Award</AwardTitle>
          <AwardText>Abierto Mexicano de Diseño</AwardText>
          <AwardText>2019</AwardText>
        </div>
        <div
          data-sal="slide-left"
          data-sal-delay="300"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          <AwardTitle>Best Project (Student Category)</AwardTitle>
          <AwardText>Mexican Society of Industrial Design</AwardText>
          <AwardText>2019</AwardText>
        </div>
        <div
          data-sal="slide-left"
          data-sal-delay="300"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          <AwardTitle>Entrepreneurship Scholarship</AwardTitle>
          <AwardText>TrepCamp UC Berkeley</AwardText>
          <AwardText>2019</AwardText>
        </div>
        <div
          data-sal="slide-left"
          data-sal-delay="300"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          <AwardTitle>Best Product Design</AwardTitle>
          <AwardText>ITESM QRO</AwardText>
          <AwardText>2018</AwardText>
        </div>
      </AwardsWrapper>
    </Container>
  )
}

export default AwardsSection
