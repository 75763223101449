import React from "react"
import styled from "styled-components"
import cvFile from "../assets/carlos-gutierrez-designer-cv.pdf"
import { pillFontMixin } from "../assets/styles/fontStyle"

const Button = styled.a`
  ${pillFontMixin}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 16px 3px;
  border: 1.4px solid ${({ theme }) => theme.color.main};
  background-color: ${({ theme }) => theme.color.mainBackground};
  border-radius: 32px;
  cursor: pointer;
  text-decoration: none;
  :hover {
    background-color: ${({ theme }) => theme.color.main};
    color: ${({ theme }) => theme.color.mainBackground};
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    padding: 7px 20px 3px;
    border: 2px solid ${({ theme }) => theme.color.main};
  }
`

type Props = {}
const DownloadCvButton = (props: Props) => {
  return (
    <Button href={cvFile} download {...props}>
      GET MY CV ↓
    </Button>
  )
}

export default DownloadCvButton
