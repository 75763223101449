import React from "react"
import styled from "styled-components"
import { Parallax } from "react-scroll-parallax"
import { pillFontMixin } from "../assets/styles/fontStyle"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const SkillParallax = styled(Parallax).attrs({
  styleInner: {
    display: "flex",
    flexDirection: "row",
  },
})`
  overflow: hidden;
`
const SkillItem = styled(({ inverted, ...rest }) => <div {...rest} />)`
  ${pillFontMixin}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 16px 3px;
  border: 1.4px solid ${({ theme }) => theme.color.main};
  background-color: ${({ inverted, theme }) =>
    inverted ? theme.color.main : theme.color.mainBackground};
  color: ${({ inverted, theme }) =>
    inverted ? theme.color.mainBackground : theme.color.main};
  border-radius: 32px;
  margin: 3px;
  white-space: nowrap;

  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    border-width: 2px;
    padding: 7px 20px 3px;
    margin: 8px;
  }
`

type Props = {}
const SkillsSection = (props: Props) => {
  return (
    <Container {...props}>
      <SkillParallax x={[40, -40]}>
        {["PRODUCT", "UI", "UX", "BRANDING"].map((item, index) => (
          <SkillItem key={`${item}-${index}`}>{item}</SkillItem>
        ))}
      </SkillParallax>
      <SkillParallax x={[-60, 60]}>
        {["ART DIRECTION", "HUMAN", "RESEARCH"].map((item, index) => (
          <SkillItem key={`${item}-${index}`} inverted={index === 1}>
            {item}
          </SkillItem>
        ))}
      </SkillParallax>
      <SkillParallax x={[80, -100]}>
        {["UX WRITING", "EMPATHY", "ENTREPRENEUR"].map((item, index) => (
          <SkillItem key={`${item}-${index}`}>{item}</SkillItem>
        ))}
      </SkillParallax>
    </Container>
  )
}

export default SkillsSection
