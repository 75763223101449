import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  paddingMobileMixin,
  paddingTabletMixin,
  paddingDesktopMixin,
} from "../assets/styles/paddingStyle"
import {
  h1Mixin,
  aboutQuoteFontMixin,
  aboutFontMixin,
} from "../assets/styles/fontStyle"

const Container = styled.section`
  ${paddingMobileMixin}
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    ${paddingTabletMixin}
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    ${paddingDesktopMixin}
  }
`

const Title = styled.div`
  ${h1Mixin}
`
const TitleDesktop1 = styled.span`
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    display: block;
    max-width: 45rem;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktopLarge}) {
    max-width: 70rem;
  }
`
const TitleDesktop2 = styled.span`
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    display: block;
    max-width: 31rem;
    margin-left: 8.6rem;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktopLarge}) {
    max-width: 50rem;
    margin-left: 13.4rem;
  }
`
const Subtitle = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    display: block;
    ${aboutQuoteFontMixin}
    margin-top: 20px;
    margin-left: 8.6rem;
    max-width: 29rem;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktopLarge}) {
    margin-left: 13.4rem;
  }
`

const ContentWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    margin-top: 60px;
    display: flex;
    align-items: flex-end;
  }
`
const Text = styled.div`
  ${aboutFontMixin}
  margin-top: 24px;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    flex: 1;
    margin: 0 60px 0 0;
  }

  strong {
    font-weight: 600;
  }
`

const ProfileImageWrapper = styled.div`
  margin-top: 24px;
  @media (min-width: ${({ theme }) => theme.screen.tablet}) {
    margin-top: 24px;
  }
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    flex: 1;
    margin: 0 0 0 60px;
  }
`
const ProfileImage = styled(GatsbyImage)`
  max-width: 640px;
  width: 100%;
`

const Quote = styled.div`
  ${aboutQuoteFontMixin}
  margin-top: 120px;
  margin-bottom: 60px;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.screen.desktop}) {
    display: none;
  }
`
type Props = {}
const AboutSection = (props: Props) => {
  const data = useStaticQuery(graphql`
    {
      profileImage: file(relativePath: { eq: "profile-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Container {...props}>
      <Title>
        <TitleDesktop1>SOLVE PROBLEMS &amp;</TitleDesktop1>{" "}
        <TitleDesktop2>STAY CURIOUS</TitleDesktop2>
      </Title>
      <Subtitle
        data-sal="slide-right"
        data-sal-delay="300"
        data-sal-duration="1000"
        data-sal-easing="ease"
      >
        “EVERYTHING I DO IS DRIVEN BY CURIOSITY &amp;&nbsp;PEOPLE”
      </Subtitle>
      <ContentWrapper>
        <Text>
          I see myself as both, a designer and a maker. Two of my main skills
          are to <strong>quickly adapt</strong> to different situations and find
          creative ways to <strong>solve problems.</strong>
          <br />
          <br />
          Throughout my career, I’ve had the pleasure to work and learn from
          talented people across multiple industries. That’s why{" "}
          <strong>
            you can expect more than just a regular set of skills from me.
          </strong>
        </Text>
        <ProfileImageWrapper>
          <ProfileImage
            title="Profile image"
            alt="Picture of me sitting on stairs and smiling"
            image={data.profileImage.childImageSharp.gatsbyImageData}
          />
        </ProfileImageWrapper>
      </ContentWrapper>
      <Quote
        data-sal="zoom-in"
        data-sal-delay="300"
        data-sal-duration="1000"
        data-sal-easing="ease"
      >
        “EVERYTHING I DO IS DRIVEN BY CURIOSITY &amp;&nbsp;PEOPLE”
      </Quote>
    </Container>
  )
}

export default AboutSection
